@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@100..900&display=swap');

* {
    font-family: "Noto Sans Hebrew", sans-serif;
    scroll-behavior: smooth;
}

.specialBg {
    /* Two radial gradients: one at top-left, another at bottom-right */
    background-image: radial-gradient(circle at top left, #313131, #181818), radial-gradient(circle at bottom right, #313131, #181818);
    background-size: cover;
}

/* Adjust the position of the gradients for small screens using a media query */
@media (max-width: 600px) {
    .specialBg {
        background-image: radial-gradient(circle at center top, #313131, #181818), radial-gradient(circle at center bottom, #313131, #181818);
    }
}


.heroBg {
    background-image: url('../src/images/herobg.jpg');

    background-size: cover;

    background-position: center;
}


.dotBg {
    width: 100%;
    height: 100%;
    /* Add your background pattern here */
    background-color: #181818;
    background-image: radial-gradient(#333 2px, transparent 0);
    background-size: 30px 30px;
    background-position: -5px -5px
}


#bottone1 {
    padding-left: 33px;
    padding-right: 33px;
    padding-bottom: 16px;
    padding-top: 16px;
    border-radius: 9px;
    background: #fdc917;
    border: none;
    font-family: inherit;
    text-align: center;
    cursor: pointer;
    transition: 0.4s;
}

#bottone1:hover {
    box-shadow: 7px 5px 56px -14px #fdc917;
}

#bottone1:active {
    transform: scale(0.97);
    box-shadow: 7px 5px 56px -10px #fdc917;
}


@keyframes float {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }
}

.text-float {
    animation: float 2s ease-in-out infinite;
}

@keyframes zoom {

    0%,
    100% {
        transform: scale(1);
        /* Original size */
    }

    50% {
        transform: scale(1.2);
        /* 20% larger */
    }
}



.text-zoom {
    animation: zoom 2s ease-in-out infinite;
}

.z-navbar {
    z-index: 100;
}








/* Main Container */
.my-container {
    background: -moz-linear-gradient(45deg, #02e1ba 0%, #26c9f2 29%, #d911f2 66%, #ffa079 100%);
    background: -webkit-linear-gradient(45deg, #02e1ba 0%,#26c9f2 29%,#d911f2 66%,#ffa079 100%);
    background: linear-gradient(45deg, #02e1ba 0%,#26c9f2 29%,#d911f2 66%,#ffa079 100%);
    background-size: 400% 400%;
    -webkit-animation: myGradient 15s ease infinite;
    -moz-animation: myGradient 15s ease infinite;
    animation: myGradient 15s ease infinite;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    overflow: hidden;
    position: relative;
}

.my-container::before, 
.my-container::after {
    content: "";
    width: 70vmax;
    height: 70vmax;
    position: absolute;
    background: rgba(255, 255, 255, 0.07);
    left: -20vmin;
    top: -20vmin;
    animation: myMorph 15s linear infinite alternate, mySpin 20s linear infinite;
    z-index: 1;
    will-change: border-radius, transform;
    transform-origin: 55% 55%;
    pointer-events: none; 
}
    
.my-container::after {
    width: 70vmin;
    height: 70vmin;
    left: auto;
    right: -10vmin;
    top: auto;
    bottom: 0;
    animation: myMorph 10s linear infinite alternate, mySpin 26s linear infinite reverse;
    transform-origin: 20% 20%; 
}

@-webkit-keyframes myGradient {
    0% {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0 50%
    }
}

@-moz-keyframes myGradient {
    0% {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0 50%
    }
}

@keyframes myGradient {
    0% {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0 50%
    }
}

@keyframes myMorph {
    0% {
        border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%; 
    }
    100% {
        border-radius: 40% 60%; 
    } 
}

@keyframes mySpin {
    to {
        transform: rotate(1turn); 
    } 
}

/* Additional Styles */
.hidden {
    display: none;
}

.inline {
    display: inline;
}

.opacity-low {
    opacity: 0.29;
}

.fill-white {
    fill: #FFFFFF;
}

.clip-path-1 {
    clip-path: url(#SVGID_2_);
    fill: #FFFFFF;
}

.clip-path-2 {
    clip-path: url(#SVGID_4_);
}

.clip-path-3 {
    clip-path: url(#SVGID_6_);
}

.clip-path-4 {
    clip-path: url(#SVGID_8_);
}

.clip-path-5 {
    clip-path: url(#SVGID_10_);
}

.no-fill {
    fill: none;
}

.clip-path-6 {
    clip-path: url(#SVGID_12_);
}

.opacity-high {
    opacity: 0.7;
}

.clip-path-7 {
    clip-path: url(#SVGID_14_);
}

.opacity-med {
    opacity: 0.2;
}

.clip-path-8 {
    clip-path: url(#SVGID_16_);
}

.opacity-fill {
    opacity: 0.3;
    fill: #FFFFFF;
    enable-background: new; 
}
